<template>
    <div class="about-container">
        <HeaderBar type="2" :logoChange="true" />
        <div class="about-content">
            <div class="banner-container"></div>

            <div class="rect-view section-ctx">
                <div class="title-1 wow fadeInUp"></div>
                <div class="ctx-1 wow fadeInUp"></div>
                <div class="ctx-2 wow fadeInUp"></div>
            </div>

            <div class="ctx-3-container">
                <div class="ctx-3 wow fadeInUp"></div>
            </div>

            <div class="rect-view section-ctx">
                <div class="ctx-4 wow fadeInUp"></div>
                <div class="ctx-5 wow fadeInUp"></div>
                <div class="ctx-6"></div>
            </div>

            <div class="swiper-container wow fadeInUp" id="swiper-container">
                <div class="swiper-wrapper">
                    <div
                        class="swiper-slide"
                        v-for="(items, index) in 13"
                        :key="index"
                        @click="showPreview(index)"
                    >
                        <img
                            :src="`https://www.hzyupin.com/images/1/${
                                index + 1
                            }.jpg`"
                            alt=""
                            srcset=""
                            class="pic-style"
                        />
                    </div>
                </div>
            </div>

            <div class="tips-text">左右滑动查看</div>
        </div>
        <Footer />
    </div>
</template>

<script>
import HeaderBar from "@/components/HeaderBar.vue";
import Footer from "@/components/Footer.vue";
import { ImagePreview } from "vant";
export default {
    components: {
        HeaderBar,
        Footer,
    },
    methods: {
        showPreview(index) {
            ImagePreview({
                images: this.list,
                closeable: true,
                startPosition: index,
            });
        },
    },
    created() {

        _hmt.push(['_trackPageview', '/pc-关于']);

        this.list = [];
        for (let i = 1; i <= 13; i++) {
            this.list.push(`https://www.hzyupin.com/images/1/${i}.jpg`);
        }

        this.$nextTick(() => {
            new Swiper("#swiper-container", {
                slidesPerView: 5,
                spaceBetween: 32,
                freeMode: true,
            });

            new WOW().init({
                offset: 10,
            });
        });
    },
};
</script>

<style lang="scss">
.about-content {
    max-width: 1920px;
    margin: 0 auto;
    background-image: url(../../public/images/31.jpg);
    .tips-text {
        margin-top: 30px;
        padding-bottom: 120px;
        text-align: center;
        font-weight: bold;
        font-size: 14px;
        color: #0f0101;
    }
    .pic-style {
        display: block;
        width: 100%;
    }
    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .swiper-container {
        height: 553px;
        min-width: 1200px;
        margin: 60px auto 0;
    }
    .ctx-6 {
        width: 1200px;
        height: 57px;
        margin: 102px auto 0;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/39.png);
    }
    .ctx-5 {
        width: 1104px;
        height: 400px;
        // margin: 100px auto 0;
        margin-top: 100px;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/38.png);
    }
    .ctx-4 {
        width: 1202px;
        height: 564px;
        margin: 148px auto 0;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/37.png);
    }
    .ctx-3 {
        width: 1202px;
        height: 739px;
        margin: 104px auto 0;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/35.png);
    }
    .ctx-3-container {
        width: 100%;
        height: 922px;
        overflow: hidden;
        margin-top: 100px;
        background-size: 1920px 922px;
        background-repeat: no-repeat;
        background-position: center top;
        background-image: url(../../public/images/36.png);
    }
    .ctx-2 {
        width: 1195px;
        height: 455px;
        margin: 100px auto 0;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/34.png);
    }
    .ctx-1 {
        width: 1202px;
        height: 400px;
        margin: 100px auto 0;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/33.png);
    }
    .title-1 {
        width: 1200px;
        height: 125px;
        margin: 92px auto 0;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url(../../public/images/32.png);
    }
    .banner-container {
        width: 100%;
        height: 699px;
        background-size: 1920px 699px;
        background-repeat: no-repeat;
        background-position: center top;
        background-image: url(../../public/images/about_bg.png);
    }
}
</style>